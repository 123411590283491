




























import { Inject } from '@cds/common';
import { Component, Vue, Prop, Emit, Watch } from 'vue-property-decorator';
import { ADD, EDIT, DETAIL } from '@/config/constant';
import { BasicInformationService } from '@/services/abilityAssessment/basic-information-service';
import { deepCopy } from '@/services/utils';
import { isEmpty, map, filter } from 'lodash';

@Component({
    components: {}
})
export default class LogDialog extends Vue {
    @Prop() private defaultData: object | null;
    @Emit() cancel() { }
    @Inject(BasicInformationService)
    private basicInformationService!: BasicInformationService;

    public ADD: string = ADD;
    public EDIT: string = EDIT;
    public DETAIL: string = DETAIL;
    public form: any = {
        size: 10,
        current: 1,
        total: 0
    };
    public tableData: any[] = [];
    public loading: Boolean = false;
    public labelPosition: String = 'right';
    public title: string = '操作日志';
    public visible: boolean = true;

    public async created(): Promise<void> {
        await this.getList();
    }
    // 初始化数据
    public initData() {
    }
    // 搜索
    public search() {
        this.form.current = 1;
        this.getList();
    }
    // 分页相关
    public handleSizeChange(size: number) {
        this.form.size = size;
        this.getList();
    }
    public handleCurrentChange(current: number) {
        this.form.current = current;
        this.getList();
    }
    // 获取数据源
    public async getList() {
        this.loading = true;
        // const res = await this.basicInformationService.queryArticleList(this.form);
        // TODO 模拟假数据
        setTimeout(() => {
            const res = {
                records: [
                    {
                        id: 1,
                        userName: '回村量',
                        createTime: '2024-10-10 09:10:10',
                        updateTime: '2024-10-15 10:30:29',
                    },
                    {
                        id: 1,
                        userName: '曼城',
                        createTime: '',
                        updateTime: '2024-10-15 10:30:29',
                    },
                ],
                total: 2,
                size: 10,
                current: 1
            };
            this.loading = false;
            this.tableData = res.records;
            this.form.total = res.total;
            this.form.size = res.size;
            this.form.current = res.current;
        }, 500);
    }
    // 关闭自己
    public async handleClose() {
        try {
            await this.$confirm('确定要取消吗?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            });
            this.cancel();
        } catch (e) { }
    }
    // 重置
    public reset() {
        this.form = {
            size: 10,
            current: 1,
            total: 0
        };
        this.search();
    }
}
