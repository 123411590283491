
























































































import { Inject } from '@cds/common';
import { Component, Vue, Prop, Emit, Watch } from 'vue-property-decorator';
import { ADD, EDIT, DETAIL, ABILITY_OPTIONS } from '@/config/constant';
import ManageDialog from './components/ManageDialog.vue';
import ReleaseDialog from './components/ReleaseDialog.vue';
import PreviewDialog from './components/PreviewDialog.vue';
import LogDialog from './components/LogDialog.vue';
import { BasicInformationService } from '@/services/abilityAssessment/basic-information-service';
import { deepCopy, keyValueConversion } from '@/services/utils';
import { isEmpty, map, filter } from 'lodash';

@Component({
  components: { ManageDialog, ReleaseDialog, PreviewDialog, LogDialog }
})
export default class typeManagement extends Vue {
  @Inject(BasicInformationService)
  private basicInformationService!: BasicInformationService;

  public ADD: string = ADD;
  public EDIT: string = EDIT;
  public DETAIL: string = DETAIL;
  public form: any = {
    size: 10,
    current: 1,
    total: 0
  };
  public tableData: any[] = [];
  public failReason: any[] = [];
  public loading: Boolean = false;
  public statusOptions: any[] = [];
  // Dialog相关
  public dialogVisible: boolean = false;
  public releaseDialogVisible: boolean = false;
  public previewDialogVisible: boolean = false;
  public logDialogVisible: boolean = false;
  public dialogType: string = '';
  public currentRow: any = null;

  public async created(): Promise<void> {
    this.statusOptions = ABILITY_OPTIONS.evaluationStatus;
    await this.getList();
  }
  // 清空数据
  public clearData() {
    this.dialogType = '';
    this.currentRow = null;
  }
  // 搜索
  public search() {
    this.form.current = 1;
    this.getList();
  }
  // 分页相关
  public handleSizeChange(size: number) {
    this.form.size = size;
    this.getList();
  }
  public handleCurrentChange(current: number) {
    this.form.current = current;
    this.getList();
  }
  // 转换状态
  public transformStatus(val: string): string {
    return keyValueConversion(val, this.statusOptions);
  }
  // 关闭新增/编辑Dialog
  public dialogCancel(isRefresh: boolean) {
    this.clearData();
    this.dialogVisible = false;
    // 刷新列表
    isRefresh && this.getList();
  }
  // 关闭发布Dialog
  public releaseDialogCancel(isRefresh: boolean) {
    this.clearData();
    this.releaseDialogVisible = false;
    // 刷新列表
    isRefresh && this.getList();
  }
  // 关闭预览Dialog
  public previewDialogCancel(isRefresh: boolean) {
    this.clearData();
    this.previewDialogVisible = false;
    // 刷新列表
    isRefresh && this.getList();
  }
  // 关闭操作日志Dialog
  public logDialogCancel() {
    this.logDialogVisible = false;
  }
  // 获取数据源
  public async getList() {
    this.loading = true;
    // const res = await this.basicInformationService.queryArticleList(this.form);
    // TODO 模拟假数据
    setTimeout(() => {
      const res = {
        records: [
          {
            id: 1,
            title: '能力测评标题1',
            status: '1',
            children: [
              {
                id: 11,
                key: 1728615816340,
              },
            ],
            maintenanceTime: '2024-10-10 09:10:10'
          },
          {
            id: 2,
            title: '能力测评标题2',
            status: '4',
            children: [
              {
                id: 11,
                key: 1728615816340,
              },
            ],
            maintenanceTime: '2024-10-12 11:25:05'
          },
        ],
        total: 2,
        size: 10,
        current: 1
      };
      this.loading = false;
      this.tableData = res.records;
      this.form.total = res.total;
      this.form.size = res.size;
      this.form.current = res.current;
    }, 500);
  }
  // 新增/编辑/查看
  public async handManage(type: string, row?: any) {
    this.dialogType = type;
    this.currentRow = type !== ADD ? JSON.parse(JSON.stringify(row)) : null;
    this.dialogVisible = true;
  }
  // 删除
  public async handDelete(row: any): Promise<void> {
    let param = {
      id: row.id
    };
    try {
      await this.$confirm('确定要删除吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      });
      // TODO 等接口调试
      // await this.basicInformationService.queryArticleInfo(param);
      this.getList();
      this.$message({
        message: '删除成功',
        type: 'success'
      });
    } catch (e) {
      this.$message({
        type: 'info',
        message: '已取消删除'
      });
    }
  }
  // 发布
  public handleRelease(row: any) {
    this.releaseDialogVisible = true;
  }
  // 预览
  public handlePreview(row: any) {
    this.previewDialogVisible = true;
  }
  // 操作日志
  public handleLog(row: any) {
    this.logDialogVisible = true;
  }
  // 重置
  public reset() {
    this.form = {
      size: 10,
      current: 1,
      total: 0
    };
    this.search();
  }
}
