export const ADD = 'ADD';
export const EDIT = 'EDIT';
export const DETAIL = 'DETAIL';

// 能力测评options
export const ABILITY_OPTIONS = {
  // 测评单状态
  evaluationStatus: [
    { label: '未发布', value: '1' },
    { label: '已发布', value: '2' },
    { label: '预览中', value: '3' },
    { label: '已完成', value: '4' }
  ]
};
