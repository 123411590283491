




















import { Inject } from '@cds/common';
import { Component, Vue, Prop, Emit, Watch } from 'vue-property-decorator';
import { ADD, EDIT, DETAIL } from '@/config/constant';
import { BasicInformationService } from '@/services/abilityAssessment/basic-information-service';
import { deepCopy } from '@/services/utils';
import { isEmpty, map, filter } from 'lodash';

@Component({
    components: {}
})
export default class ReleaseDialog extends Vue {
    @Prop() private defaultData: object | null;
    @Emit() cancel(isRefresh: boolean) {
        return isRefresh;
    }
    @Inject(BasicInformationService)
    private basicInformationService!: BasicInformationService;

    public ADD: string = ADD;
    public EDIT: string = EDIT;
    public DETAIL: string = DETAIL;
    public formInfo: any = {
        mainTypeName: '',
    };
    public rules: any = {
        // mainTypeName: [
        //     { required: true, message: '请输入测评主类型', trigger: 'blur' }
        // ]
    };
    public labelPosition: String = 'right';
    public title: string = '发布';
    public visible: boolean = true;

    public created(): void {
        this.initData();
    }
    // 初始化数据
    public initData() {
    }
    // 关闭自己
    public async handleClose() {
        try {
            await this.$confirm('确定要取消吗?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            });
            this.cancel(false);
        } catch (e) { }
    }
    // 保存表单
    public submitForm(): void {
        (this.$refs.formInfo as any).validate(async (valid: any) => {
            if (!valid) return;
            // TODO 等接口调试
            const params = {
                ...this.formInfo,
            };
            console.log('params', params);
            this.cancel(true);
        });
    }
}
